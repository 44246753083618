import { render, staticRenderFns } from "./VisionOverlay.vue?vue&type=template&id=02b18208&scoped=true"
import script from "./VisionOverlay.vue?vue&type=script&lang=js"
export * from "./VisionOverlay.vue?vue&type=script&lang=js"
import style0 from "./VisionOverlay.vue?vue&type=style&index=0&id=02b18208&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02b18208",
  null
  
)

export default component.exports