<template>
  <v-container fill-height fluid>
    <v-row>
      <v-col align="center" justify="center">
        <v-card :loading="loading" class="mx-auto mb-7" max-width="900">
          <error-overlay v-model="error" @retry="loadData"></error-overlay>
          <template slot="progress">
            <v-progress-linear
              color="#006c27"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title class="text--secondary">
            Permission Details
            <v-spacer />
            <v-chip
              v-if="permission.environment != ''"
              :color="getEnvironmentColor(permission.environment)"
              dark
            >
              {{ permission.environment }}
            </v-chip>
          </v-card-title>
          <v-card-text class="text--primary pb-2">
            <v-text-field
              :value="permission.applicationDisplayname"
              label="Application Name"
              outlined
              readonly
              dense
              hide-details
              class="mt-3"
              color="#006c27"
            >
              <v-tooltip v-if="permission.showAzureLinks" slot="append" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="margin-top: 2px"
                    dense
                    @click="linkApp"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-link
                  </v-icon>
                </template>
                <span>Link to the Consumer AppReg</span>
              </v-tooltip>
            </v-text-field>
            <v-text-field
              :value="getITSecDemandText()"
              label="IT Security Demand"
              outlined
              dense
              readonly
              hide-details
              class="mt-3"
              :error="!loading && !hasITSecDemand()"
              color="#006c27'"
            >
              <v-tooltip v-if="hasITSecDemand()" slot="append" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="margin-top: 2px"
                    dense
                    @click="linkITSec(permission.itSecURL)"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-link
                  </v-icon>
                </template>
                <span>Link to the IT Security Demand</span>
              </v-tooltip>
            </v-text-field>
            <v-text-field
              :value="permission.name"
              label="Permission Name"
              outlined
              dense
              readonly
              hide-details
              class="mt-3"
              color="#006c27"
            >
              <v-tooltip v-if="permission.showAzureLinks" slot="append" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="margin-top: 2px"
                    dense
                    @click="linkApi"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-link
                  </v-icon>
                </template>
                <span>Link to the API AppReg</span>
              </v-tooltip>
            </v-text-field>
            <v-textarea
              :value="permission.description"
              label="Permission Description"
              outlined
              dense
              readonly
              hide-details
              :auto-grow="true"
              rows="1"
              class="mt-3"
              color="#006c27"
            ></v-textarea>
            <v-text-field
              :value="formatString(permission.type)"
              label="Permission Model"
              outlined
              dense
              readonly
              hide-details
              class="mt-3"
              color="#006c27"
            ></v-text-field>
            <v-text-field
              :value="permission.tokenScope"
              label="Token Scope"
              outlined
              dense
              readonly
              hide-details
              class="mt-3"
              v-bind:class="permission.events == null ? 'my-3 ' : 'mt-3'"
              color="#006c27"
            ></v-text-field>

            <v-expansion-panels v-if="permission.events != null" class="mt-3">
              <v-expansion-panel :disabled="loading || error != null">
                <v-expansion-panel-header>
                  <div>
                    API Responsible
                    <span
                      v-if="
                        permission.DDMpresent != undefined &&
                        !permission.DDMpresent
                      "
                      ><v-icon style="font-size: 1.1rem" color="red"
                        >mdi-alert</v-icon
                      ></span
                    >
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-for="resp in permission.responsible" :key="resp.id">
                    <v-row>
                      <v-col>
                        <div style="text-align: left">
                          {{ resp.name }}
                        </div>
                      </v-col>
                      <v-col>
                        <div style="text-align: right">
                          <v-chip small>
                            {{ resp.role }}
                          </v-chip>
                          <v-chip
                            color="#006c27"
                            @click="openChat(resp)"
                            class="ml-2 white--text"
                            small
                          >
                            Open Chat
                          </v-chip>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    v-if="
                      permission != undefined &&
                      permission.responsible != undefined &&
                      permission.responsible.length != 1
                    "
                  >
                    <v-row>
                      <v-col>
                        <div style="text-align: right">
                          <v-chip
                            color="#006c27"
                            @click="openTeamChat()"
                            class="ml-2 white--text"
                            small
                          >
                            &nbsp;&nbsp;&nbsp;Open Chat with whole
                            team&nbsp;&nbsp;&nbsp;
                          </v-chip>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="!permission.DDMpresent">
                    <v-row>
                      <v-col>
                        <div style="text-align: left; color: red">
                          Problem detected! No DDM configured for this API
                          Permission. This will be reported. Please fix asap.
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel :disabled="loading || error != null">
                <v-expansion-panel-header>
                  Audit Events
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-timeline dense style="margin-left: -30px">
                    <v-timeline-item
                      v-for="(events, index) in permission.events"
                      :key="events.createdAt"
                      :color="index === 0 ? '#006c27' : 'grey'"
                      small
                    >
                      <v-row class="pt-1">
                        <v-col cols="2">
                          <div>{{ events.createdAt | formatDate }}</div>
                        </v-col>
                        <v-col
                          cols="6"
                          align-self="center"
                          style="text-align: left"
                        >
                          <div>
                            {{ events.state }}
                            <v-tooltip v-if="events.reasonText" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="#006c27"
                                  v-bind="attrs"
                                  v-blur
                                  v-on="on"
                                  @click="showReasonDialog(events)"
                                  >mdi-information-outline
                                </v-icon>
                              </template>
                              <span
                                >Additional Information:<br />{{
                                  events.reasonText
                                }}
                              </span>
                            </v-tooltip>
                          </div>
                          <div v-if="events.ticketName">
                            <a :href="getTickerLink(events)" target="_blank">{{
                              events.ticketName
                            }}</a>
                          </div>
                          <div v-if="events.itSecDemand">
                            <a :href="events.itSecURL" target="_blank">{{
                              events.itSecDemand
                            }}</a>
                          </div>
                        </v-col>
                        <v-col
                          cols="4"
                          align-self="center"
                          style="text-align: left"
                        >
                          <div>{{ events.createdBy }}</div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-actions v-if="permission.events != null" class="mr-3 pb-3">
            <v-btn
              v-if="permission.showBackLink"
              color="#006c27"
              class="white--text ml-2"
              @click="navToApp"
              >BACK</v-btn
            >
            <v-spacer></v-spacer>
            <v-dialog
              width="100%"
              v-model="implementdialog"
              persistent
              max-width="490"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  v-show="permission.ImplementationDone"
                  :disabled="loading || error != null || implementDisabled"
                  color="#006c27"
                  class="white--text"
                  >Technical approval</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="headline">
                  <v-icon class="mr-2" color="#006c27"
                    >mdi-wrench-check-outline</v-icon
                  >
                  Technical Confirmation</v-card-title
                >
                <v-card-text
                  >By confirming the technical implemenation, Schaeffler Azure
                  Global Admins will be informed to give the admin consent on
                  this permission. After this step the requesting application
                  will be able to obtain authentication tokens for the service.
                  The user will have access to the API/Data. <br />
                  <br />
                  Please make sure all technical requirments are full-filled
                  before pressing the confirm button. If you can not full-fill
                  the technical implementation please get in contact with
                  api-mgmt-support@schaeffler.com to close this permission
                  request.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="implementdialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="ConfirmImplementation()"
                  >
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              width="100%"
              v-model="grantdialog"
              persistent
              max-width="490"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  v-show="permission.grantable"
                  :disabled="loading || error != null || actionDisabled"
                  color="#006c27"
                  class="ml-2 white--text"
                  >Grant</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="headline">
                  <v-icon class="mr-2" color="#006c27"
                    >mdi-shield-check-outline</v-icon
                  >Grand Permission
                </v-card-title>
                <v-card-text v-if="!permission.threeStepFlow"
                  >By granting the permission, Schaeffler Azure Global Admins
                  will be informed to give an admin consent on your grant. After
                  this step the requesting application will be able to obtain
                  authentication tokens for your service. <br /><br />
                  Before granting the permission, please make sure all technical
                  requirements are fullfilled for the user to consume the API
                  <br /><br />
                  <strong>
                    Your grant will be a Data Domain Manager approval. If you
                    are not a DDM on this API/Data please contact
                    api-mgmt-support@schaeffler.com.
                  </strong>
                </v-card-text>
                <v-card-text v-else
                  >By granting the permission, a technical implementation
                  request will be send to the corresponding team. The technical
                  team is requested to perform all neccessary technical steps
                  which are needed for the user to use the API. <br /><br />
                  <strong>
                    Your grant will be a Data Domain Manager approval. If you
                    are not a DDM on this API/Data please contact
                    api-mgmt-support@schaeffler.com.
                  </strong>
                </v-card-text>
                <v-card-text> </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="grantdialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="green darken-1" text @click="grantPermission()">
                    Grant
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              width="100%"
              v-model="denydialog"
              persistent
              max-width="490"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  v-show="permission.grantable"
                  :disabled="loading || error != null || actionDisabled"
                  color="#006c27"
                  class="white--text ml-2"
                >
                  Deny
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">
                  <v-icon class="mr-2" color="#006c27"
                    >mdi-shield-remove-outline</v-icon
                  >
                  Deny Permission
                </v-card-title>
                <v-card-text
                  >By denying this permission the permission requester will be
                  informed. The permission requester will receive an email with
                  your deny reason.
                </v-card-text>
                <v-form v-on:submit.prevent="nop" v-model="denyReasonValid">
                  <v-text-field
                    v-model="denyReason"
                    label="Deny Reason"
                    :rules="[rules.required, rules.counter]"
                    outlined
                    counter
                    maxlength="200"
                    class="mt-3 mx-6"
                    color="#006c27"
                  >
                  </v-text-field>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="denydialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="!denyReasonValid"
                    color="green darken-1"
                    text
                    @click="denyPermission()"
                  >
                    Deny
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog width="700" v-model="reasonDialog">
      <v-card>
        <v-card-title class="text--secondary"
          >Additional Information</v-card-title
        >
        <v-card-text>
          <v-textarea
            class="mt-3"
            readonly
            color="#006c27"
            dense
            label="Reason Text"
            hide-details
            outlined
            v-model="permEvent.reasonText"
          ></v-textarea>
          <v-text-field
            v-if="permEvent.itSecDemand"
            class="mt-3"
            readonly
            color="#006c27"
            dense
            label="IT Security By Design Demand"
            hide-details
            outlined
            v-model="permEvent.itSecDemand"
          >
            <template slot="append">
              <v-icon
                color="#006c27"
                v-blur
                @click="linkITSec(permEvent.itSecURL)"
                >mdi-link</v-icon
              >
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#006c27"
            class="white--text mr-2 mb-4"
            @click="reasonDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import ErrorOverlay from "../components/ErrorOverlay.vue";
import cass from "../api/cass";

export default {
  name: "manage-permissions",

  components: { ErrorOverlay },

  async mounted() {
    this.loadData();
  },
  computed: {
    /*
    reverseEvents() {
      return this.permission.events.slice().reverse();
    },
    */
    actionDisabled() {
      if (
        this.permission.grantable == null ||
        this.permission.grantable == undefined ||
        !this.permission.grantable
      ) {
        return true;
      }
      return !this.permission.grantable;
    },
    implementDisabled() {
      return false;
    },
  },
  filters: {
    formatDate: function (value) {
      return moment(String(value)).format("DD.MM.YYYY HH:mm");
    },
  },
  methods: {
    getITSecDemandText() {
      if (this.loading) {
        return "";
      }
      if (this.permission.isExternal) {
        return "Attention: Permission request from external user";
      }
      if (this.permission.itSecTitle) {
        return `${this.permission.itSecTitle} (${this.permission.itSecId})`;
      } else {
        if (this.permission.environment == "DEVELOPMENT") {
          return "No IT Security Demand provided by requester.";
        }
        return "No IT Security Demand provided by requester. We recommend to deny this request.";
      }
    },
    getITSecDemandURL() {
      if (this.permission.itSecURL) {
        return this.permission.ItSecURL;
      } else {
        return undefined;
      }
    },
    hasITSecDemand() {
      if (this.permission.itSecId) {
        return true;
      } else {
        return false;
      }
    },
    openChat(responsible) {
      var url =
        "https://teams.microsoft.com/l/chat/0/0?tenantId=67416604-6509-4014-9859-45e709f53d3f&users=" +
        responsible.upn;
      window.open(url, "_blank");
    },
    openTeamChat() {
      var teamlist = "";
      this.permission.responsible.forEach((element) => {
        teamlist += element.upn + ",";
      });
      teamlist = teamlist.substring(0, teamlist.length - 1);

      var url =
        "https://teams.microsoft.com/l/chat/0/0?tenantId=67416604-6509-4014-9859-45e709f53d3f&users=" +
        teamlist +
        "&topicName=Permission%20Request";
      window.open(url, "_blank");
    },
    showReasonDialog(permEvent) {
      this.reasonDialog = true;
      this.permEvent = permEvent;
    },
    navToApp() {
      this.$router.push({
        name: "Application",
        params: { applicationid: this.$route.params.applicationid },
      });
    },
    loadData() {
      this.error = null;
      this.loading = true;
      cass
        .getPermission(
          this.$route.params.applicationid,
          this.$route.params.permissionid
        )
        .then((response) => {
          console.log(response);
          if (response.data.events == undefined) {
            // Prevent the normal application owner to see something here
            throw {
              message: "You are not allowed to visit this page",
              requestId: "not available",
            };
          } else {
            this.permission = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          //console.log(error.response);
          if (error.response && error.response.status == 404) {
            this.error = {
              message:
                "You are not allowed to visit this page. You need to be owner of the Azure AppReg in order to grant or deny permissions!",
              requestId: "not available",
            };
            return;
          }
          if (error.message != undefined) {
            this.error = error;
          } else {
            this.error = cass.handleError(error);
          }
        })
        .finally(() => (this.loading = false));
    },
    getTickerLink(event) {
      if (event.state === "CREATED_SNOW_WORKFLOW") {
        return (
          process.env.VUE_APP_SNOW_URL +
          "nav_to.do?uri=%2Fsc_request.do%3Fsys_id%3D" +
          event.ticketId
        );
      }
      if (event.state === "CREATED_SNOW_TICKET") {
        return (
          process.env.VUE_APP_SNOW_URL +
          "nav_to.do?uri=%2Fincident.do%3Fsys_id%3D" +
          event.ticketId
        );
      }
    },
    getEnvironmentColor(env) {
      if (env === "PRODUCTION") {
        return "#006c27";
      }
      if (env == "QUALITY") {
        return "#fccf46";
      }
      if (env == "DEVELOPMENT") {
        return "#1d9bb2";
      }
    },
    ConfirmImplementation() {
      console.log("ConfirmImplementation");
      this.implementdialog = false;
      this.loading = true;
      let data = { status: "IMPLEMENTATION_DONE" };
      cass
        .grantPermission(
          this.$route.params.applicationid,
          this.$route.params.permissionid,
          data
        )
        .then((response) => {
          console.log(response);
          this.loadData();
        })
        .catch((error) => {
          this.error = cass.handleError(error);
        })
        .finally(() => (this.loading = false));
    },
    grantPermission() {
      console.log("grantPermission");
      this.grantdialog = false;
      this.permission.grantable = false;
      this.loading = true;
      let data = { status: "GRANT" };
      cass
        .grantPermission(
          this.$route.params.applicationid,
          this.$route.params.permissionid,
          data
        )
        .then((response) => {
          console.log(response);
          this.loadData();
        })
        .catch((error) => {
          this.error = cass.handleError(error);
        })
        .finally(() => (this.loading = false));
    },
    denyPermission() {
      console.log("denyPermission");
      this.denydialog = false;
      this.permission.grantable = false;
      this.loading = true;
      let data = { status: "DENIED", denyReason: this.denyReason };
      cass
        .denyPermission(
          this.$route.params.applicationid,
          this.$route.params.permissionid,
          data
        )
        .then((response) => {
          console.log(response);
          this.loadData();
        })
        .catch((error) => {
          this.error = cass.handleError(error);
        })
        .finally(() => (this.loading = false));
    },
    linkApp() {
      let url =
        "https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Overview/appId/" +
        this.$route.params.applicationid +
        "/isMSAApp/";

      window.open(url, "_blank").focus();
    },
    linkApi() {
      let url =
        "https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Overview/appId/" +
        this.permission.resourceId +
        "/isMSAApp/";

      window.open(url, "_blank").focus();
    },
    linkITSec(url) {
      window.open(url, "_blank").focus();
    },
    formatString(value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
    nop() {
      console.log("nop");
    },
  },
  data() {
    return {
      permission: {
        name: "",
        description: "",
        type: "",
        applicationDisplayname: "",
        environment: "",
        tokenScope: "",
        events: [],
        grantable: false,
        ImplementationDone: false,
        threeStepFlow: false,
      },
      error: null,
      reasonDialog: false,
      permEvent: {},
      loading: true,
      denydialog: false,
      grantdialog: false,
      implementdialog: false,
      denyReason: "",
      denyReasonValid: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 200 || "Max 200 characters",
      },
    };
  },
};
</script>

<style scoped>
a:link {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #006c27;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #006c27;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #006c27;
  background-color: transparent;
  text-decoration: underline;
}
</style>
